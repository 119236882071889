.about__container {
    display: grid;
    grid-template-columns: 35% 55%;
    gap: 14%;

}

.about__me {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 2rem;
    background: linear-gradient(45deg, transparent, var(--color-primary),transparent);
    display: grid;
    place-items: center;
}

.about__me-image{
    border-radius: 2rem;
    overflow: hidden;
    transform: rotate(10deg);
    transition: var(--transistion);
}

.about__me-image:hover {
    transform: rotate(0deg);
}

img{
    
}

.about__content {
    width: 100%;
    padding-top: 2rem;
    
}

.about__content p{
    margin: 1rem 0 2rem;
    color: var(--color-light);
    font-weight: 100;
}

.skill-list{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 0.5rem;
    width: 75%;
    list-style-type: none;
    margin-bottom: 2rem;
    
    
}

.arrow-icon {
    color: rgb(27, 230, 162);
    font-size: 10px;
    margin-right: 10px;
}

.skills {
    text-decoration: underline;
    padding: 2px;
    
}

@media  screen and (max-width: 1024px) {
    .about__container {
        grid-template-columns: 1fr;
        gap: 0;
        margin: 1.5rem;

    }

    .about__me {
        width: 50%;
        margin: 2rem auto 4rem;
    }

   
    .about__content a{
        margin-top: 2rem;
    }

}

@media screen and (max-width: 600px) {
     .about__me {
        width: 65%;
        
        margin-bottom: 2rem;

    }

    .about__content {
        text-align: center;
    }

    .about__content p{
        text-align: justify;
    }


    .skill-list {
        margin: 0;
        width: 100%;
        
    }

    
}